import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/assets/css/main.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInstagram,faTwitter,faFacebook,faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import {MotionPlugin} from '@vueuse/motion'





library.add(faHouse, faArrowCircleRight, faInstagram, faTwitter, faFacebook, faWhatsapp,faLocationDot );

createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).use(MotionPlugin).mount('#app')
