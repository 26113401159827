<template>
  <nav class="navbar navbar-expand-lg fixed-top" id="navigation">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@/assets/img/joritech.png" width="" class="" id="nav-brand" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a href="#hero" class="nav-link">Home</a>
          </li>
          <li class="nav-item">
            <a href="#sms" class="nav-link">Bulk SMS</a>
          </li>
          <li class="nav-item">
            <a href="#others" class="nav-link">Other Services</a>
          </li>
          <li class="nav-item">
            <a href="#started" class="nav-link">Get Started</a>
          </li>
          <li class="nav-item">
            <a href="/docs" class="nav-link"  target="_blank">API Docs</a>
          </li>
          <li class="nav-item">
            <a
              href="https://bulksms.joritechsystems.co.ke/auth/login"
              class="nav-link"
              >Login</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
   
    setupNavLinks() {
      const navLinks = document.querySelectorAll(".nav-link");
      navLinks.forEach((navLink) => {
        navLink.addEventListener("click", () => {
          const navbar = document.querySelector(".navbar-collapse");
          if (!navbar) return;
          if (navbar.classList.contains("show")) {
            document.querySelector(".navbar-toggler").click();
          }
        });
      });
    },

    updateNavigationStyles() {
      const nav = document.getElementById("navigation");
   
      if (!nav) return;
      if (window.innerWidth > 768) {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
          nav.style.background = "rgb(143, 151, 121)";
        } else {
          nav.style.background = "";
        }
      } else {
        nav.style.background = "rgb(143, 151, 121)";
        const brand = document.getElementById("nav-brand");
        if (brand) brand.style.width = "140px";
      }
    },
  },

  mounted() {
    
    this.setupNavLinks();
    this.updateNavigationStyles();

    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.updateNavigationStyles);
  },
};
</script>
